import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { Accordion } from '@/components/common/Accordion/Accordion';
import { Badge } from '@/components/common/Badge';
import { breakpoints, screen } from '@/components/common/breakpoints';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { Image } from '@/components/common/image';
import { HeadingLevel, Paragraph } from '@/components/common/MarkUp';
import { Section } from '@/components/layout/Section';
import {
  getChildBackgroundColourToken,
  getTextColour,
} from '@/lib/colourUtils';
import { useMatchMedia } from '@/lib/useMatchMedia';
import { bodyLarge, headingMedium, subheadingSmall } from '@/styles/typography';

import type {
  BackgroundColourTokenType,
  TextColourTokenType,
} from '@/types/colours';
import type { SanityAccordionWithImageType } from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';

export interface AccordionWithImageProps
  extends SanityAccordionWithImageType,
    BlockWithAudience {}

const AccordionWithImage = ({
  tag,
  headingCaption,
  heading,
  subheading,
  backgroundColour,
  primaryImage,
  accordion,
  isCaptionUppercase = false,
  imagePosition = 'left',
  pageAudience,
  isAudienceSwitcherEnabled,
}: AccordionWithImageProps) => {
  const accordionRef = useRef(null);
  const [accordionHeight, setAccordionHeight] = useState(300);
  const isMobile = useMatchMedia(breakpoints.md);
  const isPrimaryImageSet = primaryImage && !!primaryImage.asset?._ref;
  const backgroundColourToken =
    backgroundColour?.token || '--background-primary';
  const childBackgroundColourToken = getChildBackgroundColourToken(
    backgroundColourToken,
    '--surface-warm-base',
  );
  const [openItems, setOpenItems] = useState([0]);

  useEffect(() => {
    if (accordionRef.current) {
      const { height } = accordionRef.current.getBoundingClientRect();
      setAccordionHeight(height);
    }
  }, []);

  const imageSelection = !isPrimaryImageSet ? (
    <>
      {!isMobile &&
        openItems.length === 0 &&
        accordion[0]?.itemImage?.asset && (
          <ImageWrapper accordionHeight={accordionHeight}>
            <Image {...accordion[0]?.itemImage} objectFit="cover" radius="l" />
          </ImageWrapper>
        )}
      {!isMobile &&
        openItems.length >= 1 &&
        accordion[openItems[0]]?.itemImage?.asset && (
          <ImageWrapper accordionHeight={accordionHeight}>
            <Image
              {...accordion[openItems[0]]?.itemImage}
              objectFit="cover"
              radius="l"
              sizes="(max-width: 960px) 100vw, 50vw"
            />
          </ImageWrapper>
        )}
    </>
  ) : (
    !isMobile && (
      <ImageWrapper accordionHeight={accordionHeight}>
        <Image {...primaryImage} objectFit="cover" radius="l" />
      </ImageWrapper>
    )
  );

  return (
    <Section
      backgroundColour={backgroundColourToken}
      colour={getTextColour(backgroundColourToken)}
      verticalPadding
      className="accordion-with-image"
    >
      {tag?.tagText && (
        <Badge
          bgColour={tag?.tagColour}
          margin="0 0 var(--spacing-xxx-small) 0"
        >
          {tag.tagText}
        </Badge>
      )}
      <HeadingLevel>
        <StyledCaptionHeadingDescription
          caption={headingCaption}
          heading={heading}
          description={subheading}
          isCaptionUppercase={isCaptionUppercase}
          backgroundColourToken={backgroundColourToken}
          pageAudience={pageAudience}
          isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
        />
        <AccordionWrapper imagePosition={imagePosition}>
          {imagePosition === 'left' && imageSelection}
          <StyledAccordion ref={accordionRef}>
            <Accordion
              backgroundColourToken={childBackgroundColourToken}
              accordionBehaviour="singleItem"
              accordionItems={accordion}
              openItemsState={[openItems, setOpenItems]}
              pageAudience={pageAudience}
              isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
            />
          </StyledAccordion>
          {imagePosition === 'right' && imageSelection}
        </AccordionWrapper>
      </HeadingLevel>
    </Section>
  );
};

const StyledCaptionHeadingDescription = styled(CaptionHeadingDescription)<{
  backgroundColourToken: BackgroundColourTokenType;
  textColour?: TextColourTokenType;
}>`
  margin-bottom: var(--spacing-x-large);

  ${Paragraph} {
    ${subheadingSmall}
  }
`;

const StyledAccordion = styled.div`
  height: fit-content;

  ul {
    border-radius: var(--radius-m);

    li {
      padding: var(--spacing-medium);
      display: grid;
    }

    span {
      ${headingMedium}
    }

    p {
      ${bodyLarge}
      padding-right: var(--spacing-xxx-large);
      padding-top: var(--spacing-xx-small);
    }
  }
`;

const AccordionWrapper = styled.div<{
  imagePosition: string;
}>`
  position: relative;
  align-items: center;

  ${screen.md} {
    display: grid;
    grid-template-columns: ${({ imagePosition }) =>
      imagePosition === 'left' ? `2fr 3fr` : `3fr 2fr`};
    gap: var(--spacing-400);
  }

  ${screen.lg} {
    gap: var(--spacing-500);
  }
`;

const ImageWrapper = styled.div<{ accordionHeight: number }>`
  display: none;
  & > img {
    height: ${(props) => `${props.accordionHeight}px`};
  }
  ${screen.md} {
    display: block;
  }
`;

export { AccordionWithImage };
